
import './App.css';
import CompWebPlayer from "./screens/CompWebPlayer";
function App() {
  return (
    <div className="App">
      <CompWebPlayer />
    </div>
  );
}

export default App;
